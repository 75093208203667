.store-logo {
    width: 130px;
    height: 130px;
    object-fit: contain;
    border-radius: 100%;
    margin: auto;
}
.store-logo-header {
    display: none;
}
@media screen and (max-width: 768px) {
    .store-logo-header {
        display: block;
        width: 50px;
        height: 50px;
        object-fit: contain;
        border-radius: 100%;
        position: absolute;
        top: 5px;
        left: 0;
        right: 0;
        margin: auto;
    }
}