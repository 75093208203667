.sampleBanners {
    border-radius: 5px;
}
@media screen and (max-width: 768px) {
.mobileItem {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.removeMargins {
    background: "#CCC";
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.sampleBanners {
    border-radius: 0px;
}
}
.carousel .control-dots {
    bottom: -14px !important;
}