@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");

.wrapperNavigation {
  background-color: #FFF;
  position: fixed;
  width: 100%;
  top: 0px; /*60px*/
  box-shadow: 0 0 5px #CCC;
  z-index: 5;
}
.navigation { 
  overflow-x: auto; 
} 
 
.navigation ul { 
  list-style-type: none; 
  white-space: nowrap; 
  padding: 0; 
  margin: 0; 
  display: flex; 
} 
 
.navigation li { 
  display: inline-block; 
  padding: 10px; 
  cursor: pointer; 
} 

.navigation ul li a .text {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size:12px;
  opacity: 0.5;
}
 
.navigation ul li.active a .text {
  opacity: 1;
}

.indicator { 
  height: 3px; 
  background-color: #007bff; /* Indicator color */ 
  transition: all 0.3s ease; 
} 
 
/* Active state indicator */ 
.navigation .active .indicator { 
  width: 100%; 
} 
 
/* Add spacing between items */ 
li + li { 
  margin-left: 10px; /* Adjust spacing as needed */ 
} 
@media screen and (min-width: 768px) {
  .wrapperNavigation {
    display: none !important;
  }
}