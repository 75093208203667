.topBarShadow {
    box-shadow: 0px 0px 5px #CCC;
}
.wrapperData {
    margin-top: 25px;
    margin-bottom: 25px;
}
.neighborhood_box {
    padding:10px 10px;
    transition: 0.2s all;
    border-radius: 5px;
}
.neighborhood_box:hover {
    background:#F5F5F5;
}
.carousel .control-dots .dot {
    background: #a6a6a6 !important;
    box-shadow: none !important;
}
.order-panel-card a:link {
    cursor: pointer !important;
    text-decoration: none !important;
    transition: 0.2s all !important;
}
.order-panel-card a:hover .MuiPaper-root {
    background-color: #e9f5ff !important;
    cursor: pointer !important;
}